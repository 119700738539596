import { useState } from "react";

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();
        setError("");

        try {
            const response = await fetch(
                "https://pp5jeffrey.wd99p.com/api/index.php",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, password }),
                }
            );

            const data = await response.json();
            console.log(data);

            if (data.status === 'success') {
                onLogin(data);
            } else {
                setError(data.message);
            }
        } catch (error) {
            console.error("Error during login:", error);
            setError("An error occurred during login");
        }
    };

    return (
        <div className="w-full h-screen bg-slate-500 flex justify-center items-center">
            <div className="w-[30%] p-10 rounded-lg bg-white">
                <h1> Login </h1>
                <hr />
                <div className="w-full">
                    <form onSubmit={handleLogin}>
                        <div className="mb-5">
                            <label htmlFor="email" className="block">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                            />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="password" className="block">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                            />
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full px-3 py-2 text-white rounded-lg bg-blue-500"
                            >
                                Login
                            </button>
                        </div>
                        {error && <div style={{ color: "red" }}>{error}</div>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
