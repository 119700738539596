import { useEffect, useState } from "react";
import ImagePlaceholder from "../assets/images/image-placeholder.jpg";
import AddPost from "./AddPost";
import { useHistory } from "react-router-dom";

const Home = ({ onLogout, user }) => {
    const [posts, setPosts] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const history = useHistory();

    async function fetchPosts() {
        const response = await fetch(
            "https://pp5jeffrey.wd99p.com/api/index.php"
        );
        const posts = await response.json();
        setPosts(posts.data);
        console.log(posts);
    }

    useEffect(() => {
        fetchPosts();
    }, [refresh]);

    const triggerRefresh = () => {
        setRefresh((prev) => !prev);
    };

    const handleLogout = async () => {
        const response = await fetch(
            "https://pp5jeffrey.wd99p.com/api/index.php",
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (data.status === "success") {
                onLogout();
                history.push("/login");
            } else {
                console.error("Failed to logout");
            }
        } else {
            console.error("Failed to logout");
        }
    };

    async function deletePost(post_id) {
        const response = await fetch(
            "https://pp5jeffrey.wd99p.com/api/index.php",
            {
                method: "DELETE",
                mode: "cors",
                body: JSON.stringify({
                    id: post_id,
                }),
            }
        );
        const res = await response.json();
        console.log(res);
        fetchPosts();
    }

    const editPost = (post_id) => {
        console.log("Edit  - ", post_id);
    };

    return (
        <div className="w-full bg-slate-500/50 pt-10">
            <div className="w-3/4 mx-auto flex flex-col justify-center items-center">
                <div className="w-full">
                    <div className="w-full flex justify-between">
                        <h3> Hello, {user.user_name} </h3>
                        <button
                            onClick={handleLogout}
                            className="px-3 py-2 rounded-lg bg-red-500/50"
                        >
                            Logout
                        </button>
                    </div>

                    <h1 className="text-center mb-5">
                        POSTrations: Post your frustration of the day...{" "}
                    </h1>
                    <AddPost userId={user.user_id} onPost={triggerRefresh} />
                    {posts.map((post) => (
                        <div
                            key={post.post_id}
                            className="w-full mb-5 rounded-lg shadow-lg"
                        >
                            <div className="bg-white p-5 rounded-t-lg">
                                <div className="flex items-center gap-3 mb-3">
                                    <img
                                        src={ImagePlaceholder}
                                        alt=""
                                        className="w-10 rounded-full shadow-2xl"
                                    />
                                    <div className="text-xs flex flex-col items-start justify-center">
                                        <p>
                                            {post.first_name} {post.last_name}
                                        </p>
                                        <p>{post.created_at}</p>
                                    </div>
                                    
                                    {user.user_id ===  post.user_id ? (
                                        <div className="flex gap-2 ml-auto">
                                            <button
                                                onClick={(e) =>
                                                    editPost(post.post_id)
                                                }
                                            >
                                                <span className="material-symbols-outlined">
                                                    edit
                                                </span>
                                            </button>
                                            <button
                                                onClick={(e) =>
                                                    deletePost(post.post_id)
                                                }
                                            >
                                                <span className="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                <div>{post.post}</div>
                            </div>
                            <div className="p-2 bg-slate-50 relative">
                                <textarea
                                    className="w-full border p-2 bg-transparent hover:bg-slate-500/20 focus:bg-slate-500/20"
                                    name="comment"
                                    id="comment"
                                ></textarea>
                                <p className="absolute bottom-5 right-5 cursor-pointer">
                                    <span className="material-symbols-outlined">
                                        send
                                    </span>
                                </p>
                            </div>
                            <div className="mb-3 w-full bg-slate-200 rounded-b-lg p-5">
                                <div className="flex items-center gap-3 mb-3">
                                    <img
                                        src={ImagePlaceholder}
                                        alt=""
                                        className="w-10 rounded-full shadow-2xl"
                                    />
                                    <div className="text-xs flex flex-col items-center justify-center">
                                        <p>{post.user}</p>
                                        <p>{post.date}</p>
                                    </div>
                                </div>

                                <div>{post.comment}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Home;
