import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { useState } from "react";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});

    const handleLogin = (user) => {
        setIsLoggedIn(true);
        setUser(user);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
    };

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/">
                        {isLoggedIn ? <Redirect to="/home" /> : <Login onLogin={handleLogin} />}
                    </Route>
                    <Route path="/login">
                        {isLoggedIn ? <Redirect to="/home" /> : <Login onLogin={handleLogin} />}
                    </Route>
                    <Route path="/home">
                        {isLoggedIn ? <Home onLogout={handleLogout} user={user} /> : <Redirect to="/login" />}
                    </Route>s
                </Switch>
            </Router>
        </div>
    );
}

export default App;