import { useState } from "react";

const AddPost = ({ userId, onPost }) => {
    const [myPost, setMyPost] = useState("");

    const handleMyPost = (e) => {
        e.preventDefault();
        async function saveMyPost() {
            const response = await fetch("https://pp5jeffrey.wd99p.com/api/index.php",
                {
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify({ userId, myPost })
                }
            )
            const result = await response.json();
            onPost();
            console.log(result);
        }

        saveMyPost();
        setMyPost("");
    }
    
    return (
        <div className="p-2 bg-slate-50 rounded-lg mb-5 relative">
            <form onSubmit={handleMyPost}>
                <textarea
                    className="w-full border p-2 bg-gray-500/20 rounded-lg relative"
                    name="myPost"
                    id="myPost"
                    placeholder="What is your Postration today?"
                    value={myPost}
                    onChange={(e) => setMyPost(e.target.value)}
                ></textarea>
                <button type="submit" className="absolute bottom-5 right-5 cursor-pointer">
                    <span className="material-symbols-outlined">send</span>
                </button>
            </form>
        </div>
    );
};

export default AddPost;
